/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { navigate } from 'gatsby-link';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../components/Button';
import Card from '../components/Card';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import CustomerCard from '../components/CustomerCard';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import SvgGlobal from '../svg/SvgGlobal';
import SvgCards from '../svg/SvgCards';
import SvgGraph from '../svg/SvgGraph';
import customerData from '../data/customer-data';
import AWS from '../svg/amazon.png';
import Stripe from '../svg/stripe.png';
import Inflybo from '../svg/inflybo.png'

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: '', email: '', message: '' };
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    console.log({ 'form-name': 'contact', ...this.state });
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };
  render() {
    return (
      <Layout>
        <section className="pt-20 md:pt-40 lg:pb-48">
          <div className="container mx-auto px-8 lg:flex justify-center">
            <div className="text-center lg:text-center">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                All-in-one platform to manage Airport VIP Lounges
              </h1>
              <p className="text-xl lg:text-2xl mt-6 font-light">
                A fully customized platform with your brand, where you can manage the entire process in your lounges.
              </p>
              <p className="mt-8 md:mt-12">
                <AnchorLink className="px-4" href="#contact">
                  <Button size="lg">Get Started</Button>
                </AnchorLink>
              </p>
            </div>
          </div>
        </section>
        <section id="platform" className="py-20 lg:pt-40">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-5xl font-semibold">Platform</h2>
            <div className="flex flex-col sm:-mx-3 mt-12">
              <img src={Inflybo} alt="Inflybo image"/>
            </div>
          </div>
        </section>
        <section id="features" className="py-20 lg:pb-40">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
            <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">Boarding Pass</p>
                  <p className="mt-4">
                    Automatically register the passengers that arrive to the lounge by easily scanning their BP. Collet in a single scan all the passengers
                    information.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">Membership Card</p>
                  <p className="mt-4">
                    Create and sell Membership Cards to your customers. The passengers receive a keypass in their email and load the Card in
                    their Phone Wallet.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">Employee</p>
                  <p className="mt-4">
                    You can create an employee card and you can record the hours of entry, exit and breaks. And create reports of the hours worked by each employee.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">Checklist</p>
                  <p className="mt-4">
                    Create a list of actions that are necessary to check in each lounge, in case there is a problem in the room, an email will be sent to those responsible.
                  </p>
                </Card>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">Contracts</p>
                  <p className="mt-4">
                    You can create and edit the contracts you have with third parties and in this way associate the entry of passengers to the specific contracts.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">Reports</p>
                  <p className="mt-4">
                    Download all your data into excel files with a single click, you can generate reports per lounges with all the passengers informations.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">Surveys</p>
                  <p className="mt-4">
                    Get a pulse on your customers satisfaction by running surveys on a digital support while the passengers are in your lounge.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">Brand</p>
                  <p className="mt-4">
                    You will have at your disposal a completely customized web platform for you, with your logos and corporate colors that you require.
                  </p>
                </Card>
              </div>
            </div>
          </div>
        </section>
        <SplitSection
          id="services"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Global view</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                An overview og all your lounges metrics organised in easy-to-use dashboards. Register as many Lounges as you want and you have a global view of all your
                Lounges with access data, frequency, type of entry and many other metrics.
              </p>
            </div>
          }
          secondarySlot={<SvgGlobal />}
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">Lounges data analysis</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Real time data at both global and lounge levels. Follow up on multiple metrics such as passengers per day, passengers per time frame and passengers per airlines.
              </p>
            </div>
          }
          secondarySlot={<SvgGraph />}
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Membership Cards</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Create Membership Cards to sell or give away to your premium customers.
                Receive a email with a keypass to load the Card in their Phone Wallet. The passengers can load the cards into their phone wallet and have them available to use in all your lounges.
              </p>
            </div>
          }
          secondarySlot={<SvgCards />}
        />
        <section id="stats" className="py-20 lg:pt-32">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-5xl font-semibold">Our numbers</h2>
            <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
              <div className="w-full sm:w-1/3">
                <StatsBox primaryText="+670.000" secondaryText="Passengers scanned" />
              </div>
              <div className="w-full sm:w-1/3">
                <StatsBox primaryText="+50.000" secondaryText="Surveys conducted" />
              </div>
              <div className="w-full sm:w-1/3">
                <StatsBox primaryText="+30" secondaryText="VIP Lounges" />
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" className="py-20 lg:py-40">
          <div className="container mx-auto">
            <LabelText className="mb-8 text-gray-600 text-center">We work with the bests</LabelText>
            <div className="flex flex-col md:flex-row md:-mx-3">
              <div className="flex-1 px-3">
                <CustomerCard name="AWS" image={AWS} />
              </div>
              <div className="flex-1 px-3">
                <CustomerCard name="stripe" image={Stripe} />
              </div>
            </div>
          </div>
        </section>
        <section
          id="contact"
          className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center"
        >
          <h3 className="text-5xl font-semibold mb-8">Ready to grow your business?</h3>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="md:flex md:items-center md-6">
              <div className="md:w-1/4">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Full Name
                </label>
              </div>
              <div className="md:w-2/4">
                <input
                  className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  name="name"
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Jane Doe"
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/4">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Email
                </label>
              </div>
              <div className="md:w-2/4">
                <input
                  className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  name="email"
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Set your email"
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/4">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Comment
                </label>
              </div>
              <div className="md:w-2/4">
                <textarea
                  className="w-full shadow-inner p-4 border-0"
                  placeholder="Write somethigs to us."
                  name="comment"
                  onChange={this.handleChange}
                  rows="6"
                />
              </div>
            </div>
            <div className="md:flex md:items-center">
              <div className="md:w-1/4" />
              <div className="md:w-2/4">
                <Button type="submit">Contact us</Button>
              </div>
            </div>
          </form>
        </section>
      </Layout>
    );
  }
}

export default Index;
