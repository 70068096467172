import React from 'react';
import Card from './Card';

const CustomerCard = ({ name, image }) => (
  <Card className="mb-8">
    <div className="items-center flex content-center flex-wrap justify-center">
      <img src={image} alt={name} />
    </div>
  </Card>
);

export default CustomerCard;
