import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en'
        }}
        title="Inflybo | All-in-one platform to manage your VIP Lounges"
        meta={[
          {
            name: `description`,
            content: 'All-in-one platform to manage your VIP Lounges'
          },
          {
            property: `og:title`,
            content: 'Inflybo | All-in-one platform to manage your VIP Lounges'
          },
          {
            property: `og:description`,
            content:
              'A fully customized platform with your brand, where you can manage the entire process in your lounges.'
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            name: `twitter:card`,
            content: `summary`
          },
          {
            name: `twitter:creator`,
            content: '@inflybo'
          },
          {
            name: `twitter:title`,
            content: 'Inflybo | All-in-one platform to manage your VIP Lounges'
          },
          {
            name: `twitter:description`,
            content:
              'A fully customized platform with your brand, where you can manage the entire process in your lounges.'
          }
        ].concat({
          meta: ['VIP Lounges', 'Airports', 'Airports Lounges', 'Lounges', 'Lounges Managment']
        })}
      />
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
